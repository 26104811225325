<template>
	<main>
		<div class="section s5">
			<a-section class="section-wrap">
				<div class="content-box">
					<div class="list">
						<div class="item" v-for="(item,index) in video" :key="index" @click="playVideo(item,index)">
							<div class="video-box">
								<video class="video" x5-video-player-type="h5-page" webkit-playsinline="true" playsinline="true"  :controls="play_index === index" :id="'video-'+item.id" :poster="cover(item)">
									<source :src="item.source"/>
								</video>
								<div class="mark" v-if="play_index !== index">
									<div class="play"></div>
								</div>
								<div class="tip" v-if="show_tip && tip_index === index">为了获得最佳观看体验，请您关闭直播后再进行此视频观看。</div>
							</div>
							<div class="info">
								<div class="element-more title">{{ item.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>

<script>
import {VideoMixins} from "@/service/views/video";
export default {
	mixins:[VideoMixins]
}
</script>
<style lang="scss" scoped>
.s5{
	.section-wrap{text-align: center;
		.content-box{margin-top: 60px;
			.list{display: flex;flex-wrap: wrap;
				.item{width: calc((100% - 22px*2)/3);margin-right: 22px;margin-bottom: 22px;cursor: pointer;
					.video-box{position: relative;
						.video{display: block;width: 100%;background: #000;object-fit:contain;}
						.mark{position: absolute;top:0;left:0;width: 100%;height:100%;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,.3);
							.play{width: 46px;height:46px;background: url("~@/assets/image/15.png") no-repeat center center;background-size: contain;}
						}
						.tip{position: absolute;bottom:0;left:0;width: 100%;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,.24);z-index: 2;text-align: center;color:#fff;font-size: 10px;padding: 5px 5px;}
					}
					.info{padding: 20px 0;font-size: 18px;font-weight: bold;color:#fff;text-align: left;}
				}
				.item:nth-child(3n){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s5{
		.section-wrap{
			.content-box{margin-top:middle( 60px);
				.list{
					.item{width: calc((100% - 22px*2*.82)/3);margin-right: middle(22px);margin-bottom: middle(22px);
						.video-box{
							.mark{
								.play{width: middle(46px);height:middle(46px);}
							}
						}
						.info{padding:middle(20px) 0;font-size: middle(18px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s5{
		.section-wrap{
			.content-box{margin-top:20px;
				.list{display: block;
					.item{width:100%;margin-right: 0;margin-bottom: 16px;
						.video-box{
							.mark{
								.play{width: 46px;height:46px;}
							}
						}
						.info{padding:10px 0;font-size: 16px;}
					}
				}
			}
		}
	}
}
</style>
